<template>
    <div class="message-error p-invalid">
        <div class="txt-error" v-if="(store.error && !store.error_data?.user)">
            {{ store.error }}
        </div>
        <div class="txt-error" v-else-if="store.error_data?.user">
            {{ store.error_data.user }}
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import useStore from "../../../../../store";

export default {
    // props: [],
    setup(props, { emit }) {
        const store = useStore();

        return{
            store
        }
    },
};
</script>


<style lang="scss" scoped>
.message-error {
    width: 100%;
    .txt-error{
        margin-top: 4px;
        padding-left: 8px;
    }
    ul{
        margin: 0;
        padding: 0;
    }
    .messages{
        font-size: 12px;
        margin-top: 4px;
        margin-left: 6px;
    }
    .forgot{
        margin-top: 8px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #2F80ED;
        span{
            cursor: pointer;
            &:hover{
                text-shadow: #2F80ED 0px 0px 0.1rem;
            }
        }
    }
}


::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    &::placeholder{
        color: #BDBDBD;       
    }
}
</style>