<template>
    <div class="login">
      <div class="limit">
        <router-link to="/account/login">
          <img src="../../../../assets/images/ommed_logo.svg" class="logo" />
        </router-link>
  
        <Form />
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed, onMounted } from "vue";
  import { useRouter } from "vue-router";
  import Form from "./Form"
  
  export default {
    components: {
      Form,
    },
    setup() {
  
      return {
      
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .login{
    display: flex;
    flex-direction: column;
    background: #F2F2F2;
    max-width: 100%;
    min-height: 100vh;
    background: url('../../../../assets/images/bg-login.png') 0% 0% / cover;
    .limit{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: max-content;
      min-height: 100vh;
      padding: 38px;
      a{
        width: max-content;
        padding: 0;
      }
      .logo{
        padding: 0;
      }
    }
  }
  
  
  @media (max-width: 1399px) {
    .register{
      .limit{
        width: 100%;
      }
    }
  }
  
  @media (min-width: 1420px) {
    .register{
      .limit{
        width: 1400px;
      }
    }
  }
  
  </style>
  