<template>
    <div class="login-form">
        <InputText
            :value="modelValue"
            class=""
            :class="conditionError.class"
            type="text"
            placeholder="Email ou telefone"
            :maxlength="100"
            @input="(e) => $emit('update:modelValue', e.target.value)"
            @keyup.enter="submit"
            autofocus
        />
        <div class="messages" v-if="conditionError.status">
            <p class="p-invalid">
                <!-- {{ msgErros.firstName[0] }} -->
            </p>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";

export default {
    props: ["modelValue", "msgErros", "submit"],
    setup(props, { emit }) {
        const text = ref("");

        const conditionError = computed(() => {
            // if( props.msgErros.firstName.length ){
            //     if( props.msgErros.firstName[0] === "Nome deve ser informado." && !props.modelValue ){
            //         return { status: true, class: 'p-invalid' }
            //     }
            //     if( props.msgErros.firstName[0] === "Nome deve possuir no mínimo 2 caracteres." && props.modelValue.length < 2 ){
            //         return { status: true, class: 'p-invalid' }
            //     }
            // }
            return { status: false, class: '' }
        })

        return{
            text,
            conditionError
        }
    },
};
</script>


<style lang="scss" scoped>
.login-form {
    width: 100%;
    ul{
        margin: 0;
        padding: 0;
    }
    .messages{
        font-size: 12px;
        margin-top: 4px;
        margin-left: 6px;
    }
}


::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    &::placeholder{
        color: #BDBDBD;       
    }
}
</style>