<template>
    <div class="form">
        <div class="header">
            <h1>Login</h1>
        </div>
        <div class="limit">
            <!-- <ul>
                <li>ddi: {{formData.ddi}}</li>
                <li>tel/email: {{formData.login}}</li>
                <li>senha: {{formData.password}}</li>
                <li>error: {{ store.error }}</li>
                <li>awaiting: {{ awaiting }}</li>
                <li>loadingsubmit: {{ loadingSubmit }}</li>
            </ul> -->
            <p class="welcome">Bem-vindo a aliança pela saúde</p>
            <DDIForm v-model="formData.ddi" />
            <LoginForm v-model="formData.login" :submit="submit" />
            <PasswordForm v-model="formData.password" :submit="submit" />
        </div>
        <div class="flex w-full justify-content-center mt-2">
            <Button class="btn-submit" :disabled="loadingSubmit" @click="submit">
                Entrar
                <i class="pi pi-spin pi-spinner" v-if="loadingSubmit" />
            </Button>
        </div>

        <div class="msg-register">
            Não tem uma conta? <a href="/account/register">Cadastre-se.</a>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import useStore from "../../../../store";

import LoginForm from "./components/LoginForm"
import PasswordForm from "./components/PasswordForm"
import DDIForm from "./components/DDIForm"
   
let awaiting = ref(false);
const loadingSubmit = ref(false);
const store = useStore();

const formData = reactive({
    ddi: "+55",
    login: "",
    password: "",
})

const submit = async () => {
    loadingSubmit.value = true;
    if (isNaN(formData.login)) {
        await store.login(formData.login, formData.password);
        awaiting.value = await store.screenResponse;
    } else {
        const number = formData.ddi + formData.login;
        await store.login(number, formData.password);
        awaiting.value = await store.screenResponse;
    }
    loadingSubmit.value = false;
};
</script>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    width: 478px;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 8px;
    padding-bottom: 32px;
    
    background: white;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 35.6%), rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 16px rgba(61, 71, 102, 0.08);
    backdrop-filter: blur(4px);
    

    h1{
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        text-align: center;
        margin: 0;
        margin-bottom: 30px;
    }
    .msg-register{
        font-family: Roboto;
        font-size: 12px;
        text-align: center;
        margin-top: 16px;
        font-weight: 400;
        font-size: 14px;
        color: #828282;
        a{
            color: #2F80ED;
            &:hover{
                text-shadow: #2F80ED 0px 0px 0.1rem;
            }
        }
    }
    .header{
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid #F2F2F2;
        text-align: center;
        h1{
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            margin: 0;
        }
    }
    .limit{
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100% !important;
        padding: 0 32px;
        .welcome{
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            text-align: center;
            margin-top: 16px;
            margin-bottom: 8px;
            color: var(--ommed);
        }
        
        
    }
    .btn-submit{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        min-width: 230px;
        height: 42px !important;
    }
}

@media (max-width: 580px){
    .form{
        width: 100%;
    }
}

::v-deep(.p-button) {
    margin-top: 10px;
    padding: 20px 80px;
    width: max-content;
    background: #2F80ED;
    border-color: #2F80ED;
    &:hover{
        background: #6daaff !important;
        border-color: #6daaff !important;
    }
}

::v-deep(input) {
    height: 50px;
    padding: 0 16px;
    border-radius: 8px;
    font-family: Roboto, sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: 1px !important;
    &:disabled{
        background: white;
        /* opacity: 1; */
        cursor: not-allowed !important;
    }
    &::placeholder{
        color: #BDBDBD;       
    }
}

::v-deep(.p-disabled) {
    background: #2F80ED !important;
    color: white !important;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
}

::v-deep(.p-dropdown) {
    border-radius: 8px;
    font-family: Roboto, sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: 1px !important;
    &:disabled{
        background: white;
        /* opacity: 1; */
        cursor: not-allowed !important;
    }
    &::placeholder{
        color: #BDBDBD;       
    }
}
</style>